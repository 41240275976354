.contattisection{
	display: flex;
	flex-wrap: wrap;
	gap: 200px;
	justify-content: center;
}

.contattiTitle{
	height: 50vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contattiTitle>h1{
	font-size: 30px;
	color: darkslategray;
	border-bottom: 2px solid gray;
	padding: 10px;
}

.contattiTitle>h2{
	padding-top: 25px;
	font-size: 25px;
	text-transform: uppercase;
}

.contattiTitle>p{
	margin-top: 5px;
}

.contatticon{
	color: #25d366;
	width: 60px;
	height: 80px;
}

@media (max-width: 640px) {
	.contattisection{
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
	}
}


