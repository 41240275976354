.strutturaAd{
	width: 100%;
	max-width: 1024px;
	z-index: 1;
	cursor: pointer;
}

.strutturaItem{
	position: relative;
	color: white;
	overflow: hidden;
	height: 350px;
}

.strutturaImg{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.strutturaTitles{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 10px;
	background-color: rgba(0,0,0,0.226);
	text-align: center; /* Aggiunto per centrare il testo all'interno del div */
}

.strutturaTitles>h1{
	font-weight: 300;
	font-size: 50px;
}

@media (max-width: 640px) {
	.strutturaItem{
	position: relative;
	color: white;
	overflow: hidden;
	height: 250px;
	}
	.strutturaTitles>h1{
	font-weight: 300;
	font-size: 35px;
	}
	.strutturaTitles{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 10px;
	background-color: rgba(0,0,0,0.226);
	text-align: center; /* Aggiunto per centrare il testo all'interno del div */
	}
}





