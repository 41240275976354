.intro{
	height: 70vh;
	background-size: cover;
	background-position: center center;
	border-bottom: 6px solid white;
}

.intro>img {
/*  position: absolute;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
   /* Per riempire completamente la sezione */
}

.bottle{
	display: flex;
	flex-direction: row;
	gap: 0px;
	flex: 3;
}

.olioImg{
	margin-left: 15%;
	border-radius: 10px;
}

.evoImg{
	margin-left: 5%;
	width: 50%;
	height: 50%;
	border-radius: 10px;
	margin-right: 20px;
}

.oliocar{
	text-align: center;
}

/*table{
	margin-top: 23%;
}*/

tr{
	height: 40px;
}

th, td {
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.shipping{
	width: 80px;
	height: 100px;
}

@media screen and (max-width: 640px) {
	.intro>img {
	/*  position: absolute;*/
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	   /* Per riempire completamente la sezione */
	}

	.vdvTitle>h1{
		font-size: 30px;
		color: darkslategray;
		border-bottom: 2px solid gray;
		padding: 10px;
		text-align: center;
	}

	.bottle{
	display: flex;
	flex-direction: column;
	}

	.evoImg{
		margin-left: 5%;
		width: 90%;
		border-radius: 10px;
	}

	.olioImg{
		margin-left: 0%;
		border-radius: 10px;
		width: 50%;
	}

	table{
		width: 200px;
		margin-left: -15%;
	}

	tr{
	height: 30px;
	}

	th, td {
	  border-bottom: 1px solid #ddd;
	  text-align: left;
	  font-size: 12px;
	}

	.mailTitle{
		font-size: 20px;
	}

	.fText{
		font-size: 13px;
		text-align: center;
	}
}

@media screen and (max-width: 767px){
	.intro>img {
	/*  position: absolute;*/
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	   /* Per riempire completamente la sezione */
	}

	.vdvTitle>h1{
		font-size: 30px;
		color: darkslategray;
		border-bottom: 2px solid gray;
		padding: 10px;
		text-align: center;
	}

	.bottle{
	display: flex;
	flex-direction: column;
	align-items: center;
	}

	.evoImg{
		margin-left: 6%;
		width: 90%;
		border-radius: 10px;
	}

	.olioImg{
		margin-left: 0%;
		border-radius: 10px;
		width: 330px;
		height: 400px;
		margin-bottom: 10px;
	}

	table{
		width: 90%;
		margin-left: 5%;
	}

	tr{
	height: 30px;
	}

	th, td {
	  border-bottom: 1px solid #ddd;
	  text-align: left;
	  font-size: 12px;
	}

	.mailTitle{
		font-size: 20px;
	}

/*	.fText{
		font-size: 13px;
		text-align: center;
	}*/
}
