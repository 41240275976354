/*.calendario{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.calendar-unavailable-badge {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%; /* Per creare la forma del bollino */
/*  display: inline-block;
}

.calend{
  width: 350px;
  height: 100px;
}*/

.dispbnbAd{
  margin-top: -20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1;
}

.dispbnbAdItem{
  position: relative;
  color: #b0a77a;
  overflow: hidden;
  height: 200px;
  width: 280px;
  margin: 10px;
  border-radius: 10px;
  border: 5px solid #b0a77a;
  cursor: pointer;
}

.dispbnbAdImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dispbnbAdTitles{
  position: absolute;
  bottom: 40%;
  left: 29%;
  padding: 10px;
  border-radius: 10px;
/*  background-color: rgba(0,0,0,0.226);*/
  background-color: rgba(255,255,255,0.900);
}

.dispbnbAdTitles>h1{
  font-weight: 300;
  font-size: 17px;
}

.dispappAdTitles{
  position: absolute;
  bottom: 40%;
  left: 40%;
  padding: 10px;
  border-radius: 10px;
/*  background-color: rgba(0,0,0,0.226);*/
  background-color: rgba(255,255,255,0.900);
}

.dispappAdTitles>h1{
  font-weight: 300;
  font-size: 17px;
}

@media (max-width: 640px) {
  .dispbnbAdItem{
  position: relative;
  color: #b0a77a;
  overflow: hidden;
  height: 200px;
  width: 200px;
  margin: 10px;
  border-radius: 10px;
  border: 5px solid #b0a77a;
  }
}