.guida{
  margin-top: -20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1;
}

.guidaItem{
  position: relative;
  color: black;
  overflow: hidden;
  height: 300px;
  width: 600px;
  margin: 10px;
  border-radius: 10px;
/*  border: 5px solid #3db3a6;*/
  cursor: pointer;
}

.guidaImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.guidaTitles{
  position: absolute;
  bottom: 10%;
  left: 10%;
  padding: 10px;
  border-radius: 10px;
/*  background-color: rgba(0,0,0,0.226);*/
  background-color: rgba(255,255,255,0.600);
}

.guidaTitles>h1{
  font-weight: 300;
  font-size: 20px;
}

.guidaTitle{
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.guidaTitle>h1{
  font-size: 40px;
  color: #3db3a6;
  border-bottom: 3px solid gray;
  padding: 10px;
}

.oliopub{
    height: 600px;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto; /* Per centrare orizzontalmente */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Per centrare orizzontalmente i contenuti */
    align-items: center; /* Per centrare verticalmente i contenuti */
    margin-top: 70px;
    margin-bottom: 50px;
  }

.oliopub>img{
    border-radius: 10px;
  }

.oliopubTit{
  max-width: 100%;
  font-size: 30px;
  margin-top: 70px;
  margin-bottom: 20px;
  color: darkslategray;
  text-align: center;
  }

@media screen and (max-width: 640px) {
  .guida{
  margin-top: -20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  }

  .guidaItem{
  position: relative;
  color: black;
  overflow: hidden;
  width: 100%;
  margin: 0px;
  margin-top: 20px;
  border-radius: 0px;
/*  border: 5px solid #b0a77a;*/
  }

  .guidaImg{
  width: 100%;
  object-fit: cover;
  }

  .guidaTitles{
  position: absolute;
  bottom: 10%;
  left: 10%;
  padding: 8px;
  border-radius: 10px;
/*  background-color: rgba(0,0,0,0.226);*/
  background-color: rgba(255,255,255,0.600);
  }

  .guidaTitles>h1{
  font-weight: 300;
  font-size: 20px;
  }

  .oliopub{
    height: 400px;
    margin-bottom: 40px;
  }

  .oliopubTit{
  max-width: 100%;
  font-size: 18px;
  margin-top: 70px;
  margin-bottom: 20px;
  color: darkslategray;
  text-align: center;
  }

  .oliopub>img{
    border-radius: 10px;
  }
}

@media screen and (max-width: 767px) {
  .guida{
  margin-top: -20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  }

  .guidaItem{
  position: relative;
  color: black;
  overflow: hidden;
  width: 100%;
  margin: 0px;
  margin-top: 20px;
  border-radius: 0px;
/*  border: 5px solid #b0a77a;*/
  }

  .guidaImg{
  width: 100%;
  object-fit: cover;
  }

  .guidaTitles{
  position: absolute;
  bottom: 10%;
  left: 10%;
  padding: 8px;
  border-radius: 10px;
/*  background-color: rgba(0,0,0,0.226);*/
  background-color: rgba(255,255,255,0.600);
  }

  .guidaTitles>h1{
  font-weight: 300;
  font-size: 20px;
  }

  .oliopub{
    height: 400px;
    margin-bottom: 40px;
  }

  .oliopubTit{
  max-width: 100%;
  font-size: 18px;
  margin-top: 70px;
  margin-bottom: 20px;
  color: darkslategray;
  text-align: center;
  }

  .oliopub>img{
    border-radius: 10px;
  }

}
