@import 'https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300';

/*section{
	height: 85vh;
	background-size: cover;
	background-position: center center;
	border-bottom: 6px solid white;
}*/

.intro{
	height: 70vh;
	background-size: cover;
	background-position: center center;
	border-bottom: 6px solid white;
}

.vdvTitle{
	height: 35vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.vdvTitle>h1{
	font-size: 35px;
	color: darkslategray;
	border-bottom: 2px solid gray;
	padding: 10px;
}

.vdvTitle>p{
	padding-top: 20px;
}

video {
/*  position: absolute;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
   /* Per riempire completamente la sezione */
}

.vdvImages{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.vdvImg{
	height: 100%;
}

.oilicon{
	width: 80px;
	height: 100px;
	margin-left: 40px;
	margin-right: 80px;
}

/*.vdvImgWrapper{
	width: 33%;
	margin: 2px;
}

.vdvImg{
	width: 100%;
	object-fit: cover;
}

.middle{
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.vdvImgWrapper:hover .vdvImg{
	opacity: 0.3;
}

.vdvImgWrapper:hover .middle{
	opacity: 1;
}*/

@media (hover: hover),(pointer:coarse) {
	.vdvImgWrapper{
	width: 33%;
	margin: 2px;
	position: relative;
	}

	.vdvImg{
	width: 100%;
	object-fit: cover;
	opacity: 1;
  	display: block;
  	transition: .5s ease;
  	backface-visibility: hidden;
  	}
	.middle{
	transition: .5s ease;
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	text-align: center;
	}

	.vdvImgWrapper:hover .vdvImg{
		opacity: 0.3;
	}

	.vdvImgWrapper:hover .middle{
		opacity: 1;
	}

	.midTitle{
		color: darkslategrey;
		font-size: 16px;
		border: 1px solid darkslategrey;
		padding: 8px 12px;
		margin-bottom: 70px;
	}

	.midDesc{
		font-size: 13px;
		margin-bottom: 20px;
		width: 350px;
	}
}

@media screen and (max-width: 950px){

	.sections{
		max-width: 100%;
		width: 100%;
	}

	.vdvImgWrapper{
		width: 100%;
		margin-bottom: 20px;
	}

	.vdvImages{
	display: flex;
	flex-direction: column;
	}

	.vdvImg{
	width: 100%;
	object-fit: cover;
	}

	.midTitle{
		color: darkslategrey;
		font-size: 16px;
		border: 1px solid darkslategrey;
		padding: 6px 10px;
		margin-bottom: 20px;
	}

	.midDesc{
		font-size: 13px;
		width: 360px;
	}

	.oilicon{
	width: 60px;
	height: 90px;
	margin-left: 20px;
	margin-right: 60px;
	}
}





