.contattiAd{
	width: 100%;
	max-width: 1024px;
	z-index: 1;
	cursor: pointer;
}

.contattiItem{
	position: relative;
	color: white;
	overflow: hidden;
	height: 350px;
	margin-bottom: 30px;
}

.contattiImg{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.contattiTitles{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 10px;
	background-color: rgba(0,0,0,0.226);
	text-align: center; /* Aggiunto per centrare il testo all'interno del div */
}

.contattiTitles>h1{
	font-weight: 300;
	font-size: 50px;
}

@media screen and (max-width: 640px) {
	.contattiItem{
	position: relative;
	color: white;
	overflow: hidden;
	height: 250px;
	}
	.contattiTitles>h1{
	font-weight: 300;
	font-size: 35px;
	}
	.contattiTitles{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 10px;
	background-color: rgba(0,0,0,0.226);
	text-align: center; /* Aggiunto per centrare il testo all'interno del div */
	}
}