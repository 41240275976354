.homeContainer{
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
}

.homeTitle{
	max-width: 1024px;
	font-size: 20px;
	margin-top: 30px;
	margin-bottom: 30px;
}

.homeTit{
	max-width: 1024px;
	font-size: 20px;
	margin-top: 30px;
	margin-bottom: 30px;
	color: darkslategray;
	text-align: center;
}

.len{
	width: 100%;
	height: 40px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 10px;
}

.flag{
	width: 200px;
	height: 30px;
	margin-right: 150px;
	margin-left: 150px;
}

@media (max-width: 950px) {
	.homeTitle{
	max-width: 1000px;
	width: 100%;
	font-size: 20px;
	margin-top: 30px;
	margin-bottom: 30px;
	text-align: center;
	}
	.homeTit{
	max-width: 1000px;
	font-size: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
	color: darkslategray;
	text-align: center;
	}
	.len{
	width: 100%;
	height: 40px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 10px;
	}

	.flag{
	width: 100px;
	height: 30px;
	margin-right: 30px;
	margin-left: 30px;
	}
}


@media (max-width: 950px) {
	.homeTitle{
	width: 100%;
	font-size: 20px;
	margin-top: 30px;
	margin-bottom: 30px;
	text-align: center;
	}
	.homeTit{
	max-width: 1000px;
	font-size: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
	color: darkslategray;
	text-align: center;
	}
	.len{
	width: 100%;
	height: 40px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 10px;
	}

	.flag{
	width: 100px;
	height: 30px;
	margin-right: 30px;
	margin-left: 30px;
	}
}

