.mail{
	width: 100%;
}

.elements{
	padding: 50px;
	margin-top: 50px;
	background-color: #b0a77a;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}

.mailInputContainer>input{
	width: 300px;
	height: 30px;
	padding: 10px;
	border: none;
	margin-right: 10px;
}

.mailInputContainer>button{
	width: 100px;
	height: 50px;
	background-color: #64b03e;
	color: white;
	font-weight: 600;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.brandicons{
	display: flex;
	flex-direction: space-between;
	align-items: center;
/*	gap: 80px;*/
}

.brandicon{
	width: 60px;
	height: 80px;
	margin-right: 75px;
}

.booking{
	width: 80px;
	height: 100px;
}

@media (max-width: 950px) {

	.mail{
	max-width: 1000px;
	width: 100%;
	}

	.brandicon{
	width: 40px;
	height: 60px;
	margin-right: 45px;
	}

	.booking{
		width: 60px;
		height: 85px;
	}
}