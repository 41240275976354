/* Aggiungi box-sizing per tutti gli elementi */
* {
    box-sizing: border-box;
}

.spiaggesec {
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.spiaggesec > h1 {
    font-size: 35px;
    color: darkslategray;
    border-bottom: 2px solid gray;
    padding: 10px;
}

.spiagge {
    width: 100%;
    /* max-width: 1024px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.spiaggeItem {
    width: 670px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 30px;
    margin-right: 10px;
    /* border: 2px solid #b0a77a;
    border-radius: 10px;
    padding-right: 10px; */
}

.spiaggeImg {
    width: 370px;
    height: 233px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 10px;
}

.spiaggeDesc {
    display: flex;
    flex-direction: column;
    gap: 13px;
    align-items: center;
    justify-content: center;
    flex: 1;
    border: 2px solid darkslategrey;
    height: 233px;
    border-radius: 10px;
    padding: 2px;
}

.spiaggeTitle {
    font-size: 25px;
    color: black;
    text-transform: uppercase;
    margin-bottom: 5px;
    text-align: center;
}

.spiaggeSubtitle {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}

.spiaggeFeatures {
    font-size: 12px;
    text-align: center;
}

.locicons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.locicon {
    height: 23px;
    margin-left: 40px;
    margin-right: 40px;
}

.grotteicons {
    margin-top: -10px;
    margin-bottom: -10px;
}

.grotteFeatures {
    font-size: 12px;
    text-align: center;
    margin-top: -10px;
}

.grotteicon {
    height: 16px;
    margin-left: 40px;
    margin-right: 40px;
}

@media screen and (max-width: 950px) {

    .spiagge {
        flex-direction: column;
    }

    .spiaggeItem {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-bottom: 30px;
        margin-right: 10px;
        /* border: 2px solid #b0a77a;
        border-radius: 10px;
        padding-right: 10px; */
    }

    .spiaggeItem .spiaggeImg {
        width: 400px;
        height: auto;
        object-fit: cover;
        border-radius: 10px;
    }

    .spiaggeItem .spiaggeDesc {
        width: 400px;
    }

    .spiaggeDesc{
    	height: auto;
    }

    .spiaggeTitle {
        font-size: 19px;
        color: black;
        text-transform: uppercase;
        text-align: center;
    }

    .spiaggeSubtitle {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .spiaggeFeatures {
        font-size: 12px;
        text-align: center;
    }

    .grotteicons {
        margin-top: -10px;
        margin-bottom: -10px;
    }

    .grotteFeatures {
        font-size: 10px;
        text-align: center;
        margin-top: -10px;
    }

    .grotteicon {
        height: 16px;
        margin-left: 20px;
        margin-right: 20px;
    }
}

/* Aggiungi overflow-x nascosto al body */
body {
    overflow-x: hidden;
}
