.optionintro>img{
	background-size: cover; /* Adatta l'immagine per coprire l'intera sezione */
  	background-position: center; /* Centra l'immagine */
  /* Altri stili della sezione */
  	height: 700px; /* Altezza desiderata della sezione */
  	width: 100%;
}

.lastitems{
	max-width: 1024;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.bContainer{
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
}

.bWrapper{
	width: 100%;
	max-width: 1024px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	position: relative;
}

.bookNow{
	position: absolute;
	top: 10px;
	right: 0;
	border: none;
	padding: 10px 20px;
	background-color: #64b03e;
	color: white;
	font-weight: bold;
	border-radius: 5px;
	cursor: pointer;
}

.bTitle{
	font-size: 24px;
}

.bAddress{
	font-size: 12px;
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 40px;
}

.bDistance{
	color: #64b03e;
	font-weight: 500;
}

.bPriceHighlight{
	color: #0071c2;
	object-fit: cover;
}

.bImages{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.bImgWrapper{
	width: 33%;
}

.bImg{
	width: 100%;
	object-fit: cover;
}

.bDetails{
	display: flex;
	justify-content: space-between;
	gap: 20px;
	margin-top: 20px;
}

.bDetailsTexts{
	flex: 3;
}

.bDesc{
	font-size: 12px;
	margin-top: 20px;
}

.bDetailsPrice{
	flex: 1;
	background-color: #d3f2ce;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.bDetailsPrice>h1{
	font-size: 18px;
	color: darkslategray;
	margin-bottom: 20px;
}

.bDetailsPrice>span{
	font-size: 13px;
	margin-bottom: 20px;
}

.bDetailsPrice>h2{
	font-weight: 300;
	font-size: 20px;
}

.linkbut{
	border: none;
	padding: 10px 20px;
	background-color: #64b03e;
	color: white;
	font-weight: bold;
	border-radius: 5px;
	cursor: pointer;
	width: 100%;
}

.slider{
	position: fixed; /*da sticky a fixed*/
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,0.426);
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center; /*aggiunto*/
}

.sliderWrapper{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sliderImg{
	max-width: 90%; /* Limita la dimensione massima dell'immagine */
  	max-height: 90%; /* Limita l'altezza massima dell'immagine */
  	object-fit: contain; /* Mantiene le proporzioni dell'immagine */
}

.close{
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 30px;
	color: lightgray;
	cursor: pointer;
}

.arrow{
	margin: 15px;
	font-size: 50px;
	color: lightgray;
	cursor: pointer;
}

.bServicesTexts{
	border-top: 1px solid lightgray;
	padding-top: 10px;
	margin-top: 60px;
	display: flex;
	flex-direction: column;
  	align-items: center;
  	justify-content: center;
}

.bspan{
	margin-top: 30px;
	font-size: 14px;
}

.serviceList{
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
	margin-bottom: 10px;
}

.serviceListItem{
	display: flex;
/*	flex-direction: column;*/
	margin-right: 10px;
	border: 1px solid lightgray;
	padding: 20px 30px;
}

.serviceListItem>span{
	font-size: 13px;
}

ul{
    list-style-type: none;
}

.bnbicon{
	margin-right: 7px;
}

hr{
	border: none;
}

.siCaratt{
	font-size: 14px;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.geodot{
	height: 15px;
	margin-right: 5px;
}

.calendar-unavailable-badge {
  background-color: red; /* Colore rosso per i giorni non disponibili */
  color: white;
  border-radius: 50%; /* Renderlo come un pallino */
  width: 10px; /* Dimensione più piccola del pallino */
  height: 10px;
  display: inline-block;
}


@media (max-width: 640px) {
	.sliderImg{
		width: 60%;
		height: 80%;
	}
}

@media screen and (max-width: 950px){
	.optionintro>img{
	background-size: cover; /* Adatta l'immagine per coprire l'intera sezione */
  	background-position: center; /* Centra l'immagine */
  	/* Altri stili della sezione */
  	height: 200px; /* Altezza desiderata della sezione */
  	width: 100%;
	}
	.bContainer{
	padding: 10px;
	}
	.bDetailsPrice{
	flex: 1;
	background-color: #d3f2ce;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	}
	.bDetailsPrice>h1{
		font-size: 14px;
		color: darkslategray;
	}
	.bDetailsPrice>span{
		font-size: 11px;
	}
	.bDetailsPrice>h2{
		font-weight: 300;
		font-size: 14px;
	}
	.bDetailsPrice>button{
		border: none;
		padding: 8px 15px;
		background-color: #64b03e;
		color: white;
		font-weight: bold;
		border-radius: 5px;
		cursor: pointer;
	}
	.serviceList{
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 10px;
		margin-bottom: 10px;
	}
	.serviceListItem{
		display: flex;
	/*	flex-direction: column;*/
		margin-right: 10px;
		margin-top: 10px;
		border: 1px solid lightgray;
		padding: 13px 15px;
	}
	.serviceListItem>span{
	font-size: 12px;
	}
	hr{
	border: none;
	}

	.bspan{
	margin-top: 30px;
	font-size: 12px;
	}

	.calendar-unavailable-badge {
	  background-color: red; /* Colore rosso per i giorni non disponibili */
	  color: white;
	  border-radius: 50%; /* Renderlo come un pallino */
	  width: 10px; /* Dimensione più piccola del pallino */
	  height: 10px;
	  display: inline-block;
	}
}

@media screen and (max-width: 950px){
	.sliderImg{
		width: 100%;
		height: auto;
	}
	.optionintro>img{
	background-size: cover; /* Adatta l'immagine per coprire l'intera sezione */
  	background-position: center; /* Centra l'immagine */
  	/* Altri stili della sezione */
  	height: 200px; /* Altezza desiderata della sezione */
  	width: 100%;
	}
	.bContainer{
	padding: 10px;
	}
	.bDetailsPrice{
	flex: 1;
	background-color: #d3f2ce;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	}
	.bDetailsPrice>h1{
		font-size: 14px;
		color: darkslategray;
	}
	.bDetailsPrice>span{
		font-size: 11px;
	}
	.bDetailsPrice>h2{
		font-weight: 300;
		font-size: 14px;
	}
	.bDetailsPrice>button{
		border: none;
		padding: 8px 15px;
		background-color: #64b03e;
		color: white;
		font-weight: bold;
		border-radius: 5px;
		cursor: pointer;
	}
	.serviceList{
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 10px;
		margin-bottom: 10px;
	}
	.serviceListItem{
/*		display: flex;*/
	/*	flex-direction: column;*/
		margin-right: 10px;
		margin-top: 10px;
		border: 1px solid lightgray;
		padding: 13px 15px;
	}

	.bnbicon{
		margin-right: 5px;
	}

	.serviceListItem>span{
	font-size: 12px;
	}
	hr{
	border: none;
	}

	.bDesc{
		font-size: 11px;
	}

	.siCaratt{
	font-size: 14px;
	text-transform: uppercase;
	margin-bottom: 7px;
	}

	.bspan{
	margin-top: 30px;
	font-size: 12px;
	}

	.calendar-unavailable-badge {
	  background-color: red; /* Colore rosso per i giorni non disponibili */
	  color: white;
	  border-radius: 50%; /* Renderlo come un pallino */
	  width: 10px; /* Dimensione più piccola del pallino */
	  height: 10px;
	  display: inline-block;
	}
}



















