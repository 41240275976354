.headerintro{
	height: 470px;
}

@media screen and (max-width: 950px){
	.headerintro{
		width: 100%;
		height: auto;
		max-width: 100%;
	}
}

/*.header{
	background-color: #5e8b47;
	color: white;
	display: flex;
	justify-content: center;
	position: relative;
}

.headerContainer{
	width: 100%;
	max-width: 1024px;
	margin: 20px 0px 100px 0px;
}

.headerContainer.listMode{
	margin: 20px 0px 0px 0px;
}

.headerList{
	display: flex;
	gap: 40px;
	margin-bottom: 50px;
}

.headerListItem{
	display: flex;
	align-items: center;
	gap: 10px;
}

.headerListItem.active{
	border: 1px solid white;
	padding: 10px;
	border-radius: 20px;
}

.headerDesc{
	margin: 20px 0;
}

@media (min-width: 650px) {
.headerSearch{
	height: 30px;
	background-color: white;
	border: 3px solid #d2ba0b;
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 10px 0;
	border-radius: 5px;
	position: absolute;
	bottom: -25px;
	width: 100%;
	max-width: 1024px;
	}
}

.headerBtn{
	background-color: #5e8b47;
	color: white;
	font-weight: 500;
	border: none;
	padding: 10px;
	cursor: pointer;
}

.headerIcon{
	color: lightgray;
}

.headerSearchItem{
	display: flex;
	align-items: center;
	gap: 10px;
}

.headerSearchInput{
	border: none;
	outline: none;
	font-size: 15px;
}

.headerSearchText{
	color: lightgray;
	cursor: pointer;
	font-size: 15px;
}

.date{
	position: absolute;
	top: 50px;
	z-index: 2;
}

.options{
	z-index: 2;
	position: absolute;
	top: 50px;
	background-color: white;
	color: grey;
	border-radius: 5px;
	-webkit-box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.4);
	box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
}

.optionItem{
	width: 200px;
	display: flex;
	justify-content: space-between;
	margin: 7px;
}

.optionCounter{
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 12px;
	color: black;
}

.optionCounterButton{
	width: 30px;
	height: 30px;
	border: 1px solid #5e8b47;
	color: #5e8b47;
	cursor: pointer;
}

.optionCounterButton:disabled{
	cursor: not-allowed;
}

@media (max-width: 640px) {
	.header{
	padding: 10px;
	}
}

@media (max-width: 640px) {
	.headerSearch{
	display: flex;
	flex-direction: column;
	align-items: center;
	}
}

@media (max-width: 640px) {
	.headerSearchItem{
	height: 20px;
	background-color: white;
	border: 3px solid #d2ba0b;
	padding: 10px 0;
	border-radius: 5px;
	bottom: 50px;
	width: 95%;
	}
}

@media (max-width: 640px) {
	.headerBtn{
	background-color: #0071c2;
	color: white;
	font-weight: 700;
	font-size: 18px;
	border: none;
	border-radius: 0px;
	cursor: pointer;
	width: 100%;
	}
}

@media (max-width: 640px) {
	.date{
	position: absolute;
	top: 380px;
	z-index: 2;
	}
}


@media (max-width: 640px) {
	.options{
		z-index: 2;
		position: absolute;
		top: 430px;
		background-color: white;
		color: grey;
		border-radius: 5px;
		-webkit-box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.4);
		box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
	}
}*/




