.bnbAd{
	width: 100%;
	max-width: 1024px;
	z-index: 1;
	cursor: pointer;
}

.bnbAdItem{
	position: relative;
	color: #b0a77a;
/*	overflow: hidden;*/
	height: 420px;
	border-radius: 10px;
	border: 5px solid #b0a77a;
}

.bnbAdImg{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.bnbAdTitles{
	position: absolute;
	bottom: 40%;
	left: 26%;
	padding: 10px;
	border-radius: 10px;
/*	background-color: rgba(0,0,0,0.226);*/
	background-color: rgba(255,255,255,0.900);
}

.bnbAdTitles>h1{
	font-weight: 300;
	font-size: 50px;
}

@media (max-width: 640px) {
	.bnbAdItem{
	position: relative;
	color: #b0a77a;
/*	overflow: hidden;*/
	height: 250px;
	border-radius: 10px;
	border: 5px solid #b0a77a;
	}
	.bnbAdTitles>h1{
	font-weight: 300;
	font-size: 35px;
	}
	.bnbAdTitles{
	position: absolute;
	bottom: 35%;
	left: 15%;
	padding: 7px;
	background-color: rgba(255,255,255,0.900);
	}
}