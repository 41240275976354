.spiaggesec{
	height: 20vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.spiaggesec>h1{
	font-size: 35px;
	color: darkslategray;
	border-bottom: 2px solid gray;
	padding: 10px;
}

.spiagge{
	width: 100%;
/*	max-width: 1024px;*/
	display: flex;
	flex-direction: space-between;
	align-items: center;
	justify-content: center;
	gap: 20px;
}

.spiagge{
	display: flex;
	flex-wrap: wrap;
}

.spiaggeItem{
	width: 670px;
	display: flex;
	flex-direction: space-between;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin-bottom: 30px;
	margin-right: 10px;
	/*border: 2px solid #b0a77a;
	border-radius: 10px;
	padding-right: 10px;*/
}

.spiaggeImg{
	width: 370px;
	height: 230px;
	object-fit: cover;
	border-radius: 10px;
	margin-right: 10px;
}

.spiaggeDesc{
	display: flex;
	flex-direction: column;
	gap: 13px;
	align-items: center;
	justify-content: center;
	flex: 1;
	border: 2px solid darkslategrey;
	height: 230px;
	border-radius: 10px;
	padding: 2px;
}

.spiaggeTitle{
	font-size: 25px;
	color: black;
	text-transform: uppercase;
	margin-bottom: 5px;
}

.spiaggeSubtitle{
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
}

.spiaggeFeatures{
	font-size: 12px;
	text-align: center;
}

.locicons{
	display: flex;
	flex-direction: space-between;
	align-items: center;
	justify-content: center;
}

.locicon{
	height: 23px;
	margin-left: 40px;
	margin-right: 40px;
}

@media screen and (max-width: 640px) {
	.spiaggeItem{
	width: 500px;
	display: flex;
	flex-direction: space-between;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin-bottom: 30px;
	margin-right: 10px;
	/*border: 2px solid #b0a77a;
	border-radius: 10px;
	padding-right: 10px;*/
	}

	.spiaggeImg{
		width: 300px;
		height: 230px;
		object-fit: cover;
		border-radius: 10px;
	}

	.spiaggeTitle{
	font-size: 16px;
	color: black;
	text-transform: uppercase;
	}

	.spiaggeSubtitle{
		font-size: 12px;
		font-weight: bold;
		text-transform: uppercase;
	}

	.spiaggeFeatures{
		font-size: 10px;
		text-align: center;
	}
}




