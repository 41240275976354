.searchItem{
/*	border: 1px solid lightgray;*/
	padding: 10px;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	gap: 20px;
	margin-bottom: 20px;
}

.siImg{
	/*width: 200px;
	height: 200px;*/
	width: 500px;
	height: 400px;
	object-fit: cover;
	border-radius: 10px;
}

.siDesc{
	display: flex;
	flex-direction: column;
/*	gap: 30px;*/
	align-items: center;
	justify-content: center;
	flex: 1;
}

.siTitle{
	font-size: 40px;
	color: #b0a77a;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.siDistance{
	font-size: 12px;
}

.siBreakOp{
	font-size: 16px;
	border: 1px solid #b0a77a;
	background-color: darkslategray;
	color: white;
	width: max-content;
	padding: 3px;
	border-radius: 5px;
	text-transform: uppercase;
}

.siSubtitle{
	font-size: 20px;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 5px;
}

.siFeatures{
	font-size: 14px;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.siCancelOp{
	font-size: 12px;
	color: #0071c2;
	font-weight: bold;
}

.siCancelOpSubtitle{
	font-size: 12px;
	color: #0071c2;
}

.siDetails{
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.siRating{
	display: flex;
	justify-content: space-between;
}

.siRating>span{
	font-weight: 500;
}

.siRating>button{
	background-color: #64b03e;
	color: white;
	padding: 5px;
	font-weight: bold;
	border: none;
}

.siDetailTexts{
	text-align: right;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.siPrice{
	font-size: 24px;
}

.siTaxOp{
	font-size: 12px;
	color: gray;
}

.siCheckButton{
	background-color: #64b03e;
	color: white;
	font-weight: bold;
	padding: 10px 5px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
}

.siserviceList{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
/*	gap: 10px;*/
	margin-bottom: 10px;
}

.siserviceListItem{
	display: flex;
/*	flex-direction: column;*/
	margin-bottom: 10px;
	margin-right: 10px;
	/*border: 1px solid lightgray;
	padding: 20px 30px;*/
}

.siserviceListItem>span{
	font-size: 13px;
}

.react-icons{
	width: 20px;
	height: 20px;
	margin-right: 5px;
}

@media screen and (max-width: 640px) {
	.siImg{
	width: 230px;
	height: 250px;
	object-fit: cover;
	}

	.siRating{
	display: flex;
	justify-content: space-between;
	gap: 5px;
	}
	.siTitle{
	font-size: 25px;
	color: #b0a77a;
	text-transform: uppercase;
	margin-bottom: 10px;
	}

	.siSubtitle{
	font-size: 13px;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 10px;
	}

	.siFeatures{
	font-size: 11px;
	text-transform: uppercase;
	margin-bottom: 10px;
	}

	.siPrice{
	font-size: 20px;
	}
	.siCheckButton{
	background-color: #64b03e;
	color: white;
	font-weight: bold;
	font-size: 12px;
	padding: 8px 3px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	}
	.siRating>button{
	background-color: #64b03e;
	color: white;
	padding: 5px;
	font-weight: bold;
	border-radius: 4px;
	}
	.siserviceList{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
	margin-bottom: 5px;
	}
	.siserviceListItem{
		display: flex;
	/*	flex-direction: column;*/
		margin-bottom: 0px;
		margin-right: 5px;
		/*border: 1px solid lightgray;
		padding: 20px 30px;*/
	}

	.siserviceListItem>span{
	font-size: 9px;
	}

	.siBreakOp{
	font-size: 12px;
	border: 1px solid #b0a77a;
	background-color: darkslategray;
	color: white;
	width: max-content;
	padding: 3px;
	border-radius: 5px;
	text-transform: uppercase;
	}

	.react-icons{
	width: 15px;
	height: 15px;
	margin-right: 5px;
	}
}

@media screen and (max-width: 767px){
    .siImg{
	width: 230px;
	height: 260px;
	object-fit: cover;
	}

	.siRating{
	display: flex;
	justify-content: space-between;
	gap: 5px;
	}
	.siTitle{
	font-size: 30px;
	color: #b0a77a;
	text-transform: uppercase;
	margin-bottom: 5px;
	}

	.siSubtitle{
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 3px;
	}

	.siFeatures{
	font-size: 11px;
	text-transform: uppercase;
	margin-bottom: 10px;
	}

	.siPrice{
	font-size: 20px;
	}
	.siCheckButton{
	background-color: #64b03e;
	color: white;
	font-weight: bold;
	font-size: 12px;
	padding: 8px 3px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	}
	.siRating>button{
	background-color: #64b03e;
	color: white;
	padding: 5px;
	font-weight: bold;
	border-radius: 4px;
	}
	.siserviceList{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
	margin-bottom: 5px;
	}
	.siserviceListItem{
		display: flex;
	/*	flex-direction: column;*/
		margin-bottom: 2px;
		margin-right: 5px;
		/*border: 1px solid lightgray;
		padding: 20px 30px;*/
	}

	.siserviceListItem>span{
	font-size: 9px;
	}

	.siBreakOp{
	font-size: 12px;
	border: 1px solid #b0a77a;
	background-color: darkslategray;
	color: white;
	width: max-content;
	padding: 3px;
	border-radius: 5px;
	text-transform: uppercase;
	}

	.react-icons{
	width: 15px;
	height: 15px;
	margin-right: 5px;
	}
}






