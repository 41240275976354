.appartamentiAd{
	width: 100%;
	max-width: 1024px;
	z-index: 1;
	cursor: pointer;
}

.appartamentiAdItem{
	position: relative;
	color: #b0a77a;
	overflow: hidden;
	height: 420px;
	border-radius: 10px;
	border: 5px solid #b0a77a;
}

.appartamentiAdImg{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.appartamentiAdTitles{
	position: absolute;
	bottom: 40%;
	left: 30%;
	padding: 10px;
	border-radius: 10px;
/*	background-color: rgba(0,0,0,0.226);*/
	background-color: rgba(255,255,255,0.900);
}

.appartamentiAdTitles>h1{
	font-weight: 300;
	font-size: 50px;
}

@media (max-width: 640px) {
	.appartamentiAdItem{
	position: relative;
	color: #b0a77a;
	overflow: hidden;
	height: 250px;
	border-radius: 10px;
	border: 5px solid #b0a77a;
	}
	.appartamentiAdTitles>h1{
	font-weight: 300;
	font-size: 35px;
	}
	.appartamentiAdTitles{
	position: absolute;
	bottom: 35%;
	left: 15%;
	padding: 7px;
	background-color: rgba(255,255,255,0.900);
	}
}