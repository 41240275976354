.listContainer{
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
}

.listResult{
	width: 100%;
	max-width: 1024px;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 20px;
}

/*.listContainer{
	display: flex;
	justify-content: center;
	margin-top: 20px;
}*/

/*.listWrapper{
	width: 100%;
	max-width: 1024px;
	display: flex;
	gap: 20px;
}*/

/*.listSearch{
	flex: 1;
	background-color: #64b03e;
	padding: 10px;
	border-radius: 10px;
	position: sticky;
	top: 10px;
	height: max-content;
}*/

/*.listResult{
	flex: 2;
}*/

.lsTitle{
	font-size: 20px;
	color: white;
	margin-bottom: 10px;
}

.lsItem{
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin-bottom: 10px;
}

.lsItem>label{
	font-size: 12px;
}

.lsItem>input{
	height: 30px;
	border: none;
	padding: 5px;
}

.lsItem>span{
	height: 30px;
	padding: 5px;
	background-color: white;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.lsOptionItem{
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	font-size: 12px;
}

.lsOptionInput{
	width: 50px;
}

.lsOptions{
	padding: 10px;
}

.listSearch>button{
	padding: 10px;
	background-color: #5e8b47;
	color: white;
	border: none;
	width: 100%;
	font-weight: 500;
	cursor: pointer;
}


@media (max-width: 640px) {
	.siRating{
	display: flex;
	justify-content: space-between;
	gap: 5px;
	}
	.siImg{
	width: 150px;
	height: 230px;
	object-fit: cover;
	}
	.siPrice{
	font-size: 20px;
	}
	.siCheckButton{
	background-color: #64b03e;
	color: white;
	font-weight: bold;
	font-size: 12px;
	padding: 8px 3px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	}
	.siRating>button{
	background-color: #64b03e;
	color: white;
	padding: 5px;
	font-weight: bold;
	border-radius: 4px;
	}
	.siserviceList{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
	margin-bottom: 10px;
	}
}



























