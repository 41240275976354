.footer{
	width: 100%;
/*	max-width: 1024px;*/
	font-size: 14px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.fLists{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 50px;
}

.fList{
	list-style: none;
	padding: 0;
}

.fListItem{
	margin-bottom: 10px;
	color: #5e8b47;
	cursor: pointer;
}

.fText{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
}

