.olioList{
	width: 70%;
	display: flex;
	justify-content: space-between;
	gap: 20px;
	margin-left: 15%;
}

.olioListItem{
	flex: 1;
	border-radius: 10px;
	overflow: hidden;
	width: 100%;
	margin-right: 10px;
}

.olioListImg{
	width: 100%;
	height: 400px;
	border-radius: 10px;
	object-fit: cover;
}

h1{
	margin-bottom: 0px;
	margin-top: 3px;
}

h2{
	margin-top: 0px;
}

.olioListTitles>h1{
	font-size: 18px;
	margin-left: 23%;
	margin-top: 6px;
}

.olioListTitles>h2{
	font-size: 14px;
	font-weight: 500;
	margin-left: 23%;
	margin-top: 10px;
}

.oliobutton{
display: inline-block;
outline: 0;
border: none;
cursor: pointer;
line-height: 1.2rem;
font-weight: 800;
background: #007a5a;
padding: 8px 14px 9px;
font-size: 17px;
border-radius: 10px;
color: #fff;
height: 36px;
transition: all 75ms ease-in-out;
width: 100%;
margin-top: 10px;
}

button:hover{
	box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
}

@media screen and (max-width: 640px) {
	.olioList{
	width: 200px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 10px;
	margin-left: 30%;
	}
	.olioListItem{
	flex: 1;
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 60px;
	margin-right: 10px;
	}

	.olioListImg{
	width: 200px;
	height: 280px;
	border-radius: 10px;
	object-fit: cover;
	cursor: pointer;
}

	.olioListTitles>h1{
	font-size: 14px;
	}
	.olioListTitles>h2{
	font-size: 12px;
	}
	.oliobutton{
	width: 100%;
	}
}

@media screen and (max-width: 767px){
	.olioList{
	width: 200px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 10px;
	margin-left: 30%;
	}
	.olioListItem{
	flex: 1;
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 60px;
	margin-right: 10px;
	}

	.olioListImg{
	width: 200px;
	height: 280px;
	border-radius: 10px;
	object-fit: cover;
	cursor: pointer;
}

	.olioListTitles>h1{
	font-size: 14px;
	}
	.olioListTitles>h2{
	font-size: 12px;
	}
	.oliobutton{
	width: 100%;
	}
}


